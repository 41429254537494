(function($) {
// Carte des services en sidebar
    if( $('#map_service').length > 0 ){


        var lat = $('#map_service').data("lat");
        var lng = $('#map_service').data("lng");

        var map_carte = L.map('map_service', {
            center: [lat,lng],
            zoom: 13,
        });

        L.tileLayer.provider('OpenStreetMap.France').addTo(map_carte);

        var marker_off = L.icon({
            iconUrl: main_js.templateUrl + '/dist/images/marker_off.png',
            iconSize: [28,42],
        });

        var marker = L.marker([lat,lng],{icon: marker_off}).addTo(map_carte);

    }
})(jQuery);